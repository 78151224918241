<template>
	<div class="slider-container">
		<transition-group name="fade" tag="div">
			<div v-for="i in [currentIndex]" :key="i" class="image-container">
				<img :src="currentImg" class="image" />
			</div>
		</transition-group>

		<a class="prev" @click="prev" href="#">&#10094;</a>
		<a class="next" @click="next" href="#">&#10095;</a>
	</div>
</template>

<script>
import axios from 'axios'

export default {
	name: "Slider",
	data() {
		return {
			token: localStorage.getItem('token'),
			images: [],
			timer: null,
			currentIndex: 0,
		};
	},
	methods: {
		startSlide() {
			this.timer = setInterval(this.next, 4000);
		},
		next() {
			this.currentIndex += 1;
		},
		prev() {
			this.currentIndex -= 1;
		},
		getImage() {
			axios.get(`slideshow?token=${this.token}`)
				.then(res => {
					res.data.forEach(data => {
						this.images.push(data.image_url);						
					});
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		}
	},
	computed : {
		currentImg() {
			return this.images[Math.abs(this.currentIndex) % this.images.length];
		}
	},
	created() {
		this.getImage();
	},
	mounted() {
		this.startSlide();
	}
};
</script>

<style scoped>

.slider-container {
	display: block;
	height: 150px;
	position: relative;
}

.image-container {
	width: 100%;
	height: 150px;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.prev, .next {
	cursor: pointer;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: auto;
	padding: 10px;
	color: white;
	font-weight: bold;
	font-size: 18px;
	transition: 0.7s ease;
	border-radius: 0 4px 4px 0;
	text-decoration: none;
	user-select: none;
}

.next {
	border-radius: 4px 0 0 4px !important;
}

.next {
  	right: 0;
}

.prev {
  	left: 0;
}

.prev:hover, .next:hover {
  	background-color: rgba(0,0,0,0.9);
}

.fade-enter-active,
.fade-leave-active {
	transition: all 0.9s ease;
	overflow: hidden;
	visibility: visible;
	position: absolute;
	width:100%;
	opacity: 1;
}

.fade-enter,
.fade-leave-to {
	visibility: hidden;
	width:100%;
	opacity: 0;
}

</style>