<template>
    <div class="app">
		<transition name="fade">
            <div v-if="show">
				<header>
					<div class="search-group">
						<input type="text" placeholder="Find product" @keypress.enter="searchProduct" v-model="searchKey">
						<i class="far fa-search"></i>
					</div>

					<div class="header-icon">
						<router-link to="/cart" class="fal fa-shopping-cart"></router-link>
						<router-link to="/chat" class="fal fa-comments-alt"></router-link>
					</div>
				</header>

				<div class="sidebar">
					<Slider></Slider>
				</div>
				
				<div class="container">
					<main class="mt-2">
						<div class="hello mt-4" v-if="me">
							<h6>Hi, {{ me.name }}!</h6>
							<p>What do you want to buy today?</p>

							<div class="break"></div>
						</div>

						<div class="categories">
							<div class="category">
								<div class="icon">
									<router-link to="/search?key=musik">
										<i class="fas fa-music"></i>
									</router-link>
								</div>

								<p class="caption">Music</p>
							</div>

							<div class="category">
								<div class="icon">
									<router-link to="/search?key=pakaian">
										<i class="fas fa-tshirt"></i>
									</router-link>
								</div>

								<p class="caption">Clothes</p>
							</div>

							<div class="category">
								<div class="icon">
									<router-link to="/search?key=aksesoris">
										<i class="fas fa-scarf"></i>
									</router-link>
								</div>

								<p class="caption">Accessories</p>
							</div>

							<div class="category">
								<div class="icon">
									<router-link to="/search?key=kerajinan">
										<i class="fas fa-disease"></i>
									</router-link>
								</div>

								<p class="caption">Craft</p>
							</div>
						</div>

						<div class="products">
							<h6 class="title mb-4">Recomended</h6>

							<div class="row">
								<div class="col-lg-6" v-for="product in products" :key="product.id" v-if="product.recomended == 'Y'">
									<div class="product mb-4" @click="showProduct(product.id)">
										<div class="image">
											<img :src="product.image_url" alt="Product 1">
										</div>

										<div class="detail">
											<p class="title">{{ product.name }}</p>

											<div class="bottom">
												<p class="price">Rp. {{ Number(product.min).toLocaleString() }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="products">
							<h6 class="title mb-4">All Products</h6>

							<div class="row">
								<div class="col-lg-6" v-for="product in products" :key="product.id" v-if="product.recomended == 'N'">
									<div class="product mb-4" @click="showProduct(product.id)">
										<div class="image">
											<img :src="product.image_url" alt="Product 1">
										</div>

										<div class="detail">
											<p class="title">{{ product.name }}</p>

											<div class="bottom">
												<p class="price">Rp. {{ Number(product.min).toLocaleString() }}</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</main>
				</div>
				
				<Nav></Nav>
			</div>
		</transition>
		
		<transition name="fade">
			<div v-if="!show">
				<div class="container d-flex flex-column justify-content-center align-items-center">
					<div class="logo">
						<img src="@/assets/logo.png" alt="Logo">
					</div>

					<div class="detail">
						<h5 class="tagline">The First Borneo Arts Marketplace</h5>
						<p class="motto m-0">Developed by</p>
						<p class="motto m-0">Rinyuakng Squad</p>
						<p class="motto m-0">2021</p>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Nav from '@/components/UserNav.vue'
import Slider from '@/components/Slider.vue'
import axios from 'axios'

export default {
	components: {
		Nav,
		Slider
	},
	data() {
		return {
			token: localStorage.getItem('token'),
			me: '',
			searchKey: '',
			products: '',
			show: false
		}
	},
	methods: {
		getMe() {
			axios.get(`auth/me?token=${this.token}`)
				.then(res => {
					this.me = res.data;
				});
		},
		getProduct() {
			axios.get(`product`)
				.then(res => {
					this.products = res.data;
				})
				.catch(err => {
					console.log(err.response.data);
				}); 
		},
		showProduct(id) {
			this.$router.push(`product/${id}`);
		},
		searchProduct() {
			this.$router.push(`/search?key=${this.searchKey}`);
		}
	},
	created() {
		this.getProduct();

		if (this.token) this.getMe();

		setTimeout(() => {
			this.show = true;
		}, 1500);
	}
}
</script>

<style scoped>

.logo {
	width: 80%;
	display: flex;
	justify-content: center;
	margin: 100px 0 30px 0;
}

.detail {
	text-align: center;
}

.detail .tagline {
	font-family:'Times New Roman', Times, serif;
	font-size: 22px;
	margin-bottom: 15px;
	color: #C9201D;
}

.detail .motto {
	font-size: 13px;
}

header {
	height: 60px;
	padding: 5px 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--primary);
	border-bottom: 1px solid rgb(236, 236, 236);
}

.search-group {
	width: 80%;
	position: relative;
}

.search-group input {
	width: 100%;
	height: 25px;
	background-color: white;
	border-radius: 20px;
	border: none;
	padding: 15px;
	outline: none;
	font-size: .8rem;
}

.search-group i {
	position: absolute;
	right: 10px;
	top: 50%;
	transform: translateY(-50%);
	color: gray;
}

.header-icon a {
	font-size: 1.25rem;
	text-decoration: none;
	color: white;
	margin-left: .8rem;
}

.categories {
	padding: 20px 0;
	display: flex;
	justify-content: space-between;
}

.category {
	cursor: pointer;
}

.category .icon {
	padding: 10px;
	border-radius: 20px 0 20px 0;
	width: 70px;
	height: 70px;
	background-color: var(--primary2);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
}

.category .icon i {
	font-size: 2rem;
	color: white;
}

.category .caption {
	text-align: center;
	font-size: 13px;
	margin-top: 4px;
}

.product {
	overflow: hidden;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 220px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
	cursor: pointer;
}

.product .image {
	height: 150px;
}

.product .detail {
	height: 20px;
	padding: 10px;
}

.detail .title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin: 0;
	font-size: 14px;
	text-align: left;
}

.detail .bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bottom .sold {
	margin: 0;
	font-size: 11px;
	color: gray;
}

.bottom .price {
	margin: 0;
	font-size: 14px;
	font-weight: 600;
}

.container {
	height: fit-content;
}

.icon, .icon a {
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon img {
	width: 85%;
	height: 85%;
}

.break {
	margin-left: -10%;
	width: 120%;
	height: 8px;
	background-color: rgb(227, 244, 255);
}

.hello p {
	font-size: 12px;
}

</style>